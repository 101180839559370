import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-55965ec9")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex gap-2" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["onClick"]
_popScopeId()


import { useFeeConfigListStore } from "@/store/pinia/gws-master/fee-config/useFeeConfigListStore";
import { checkButtonPermission } from "@/core/helpers/helpers-utils";

//props

export default _defineComponent({
  props: {
  listData: {
    required: true,
    type: Array,
  },
  page: {
    required: true,
    type: Number,
    default: 1,
  },
},
  setup(__props) {

const props = __props

const listStore = useFeeConfigListStore();

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_unref(listStore).loading)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
        [_directive_loading, _unref(listStore).loading]
      ])
    : _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        key: 1,
        data: props.listData,
        "empty-text": 'No Data',
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "no",
            label: "No.",
            "min-width": "20",
            type: "index",
            align: "center"
          }),
          _createVNode(_component_el_table_column, {
            prop: "name",
            label: "Nama",
            "min-width": "150",
            align: "left"
          }),
          _createVNode(_component_el_table_column, {
            prop: "fix_amount",
            label: "Value",
            width: "100"
          }),
          _createVNode(_component_el_table_column, {
            prop: "is_active",
            label: "Status"
          }),
          _createVNode(_component_el_table_column, {
            prop: "created",
            label: "Dibuat"
          }),
          _createVNode(_component_el_table_column, {
            prop: "modified",
            label: "Diubah"
          }),
          _createVNode(_component_el_table_column, {
            prop: "recent",
            label: "Fee Sebelumnnya"
          }),
          _createVNode(_component_el_table_column, {
            prop: "action",
            label: "Aksi"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("button", {
                  class: "btn-edit",
                  id: scope.id
                }, [
                  (_unref(checkButtonPermission)('U'))
                    ? (_openBlock(), _createBlock(_component_inline_svg, {
                        key: 0,
                        src: `media/gws/pencil-alt.svg`,
                        onClick: ($event: any) => (_ctx.handleEditData(scope.row))
                      }, null, 8, ["src", "onClick"]))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_3),
                (_unref(checkButtonPermission)('U'))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn-delete",
                      onClick: ($event: any) => (_ctx.handleDeleteData(scope.row))
                    }, [
                      _createVNode(_component_inline_svg, { src: `media/gws/trash.svg` }, null, 8, ["src"])
                    ], 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _unref(listStore).loading]
      ])
}
}

})